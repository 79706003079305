import React from "react";
import styled, {css} from "styled-components"

const SharedContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto;
`

const DesktopContainer = styled.div`
    ${SharedContainer}
`

const MobileContainer = styled.div`
    ${SharedContainer}

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const Grid = ({children, allwaysDesktop, ...props}) => {
    return (
        allwaysDesktop ? 
            <DesktopContainer {...props}>
                {children}
            </DesktopContainer> :
            <MobileContainer {...props}>
                {children}
            </MobileContainer>

    )
}

export default Grid