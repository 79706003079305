import React from "react";
import styled from "styled-components"
import { Link } from "gatsby"

const StyledList = styled.ul`
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    margin: 0;

    @media only screen and (max-width: 1000px) {
        background: rgba(36, 133, 155, 0.98);
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-start;
        left: 0px;
        margin: auto 0px;
        overflow: hidden;
        overflow-y: hidden;
        padding: 0;
        position: absolute;
        text-align: center;
        transform: translate3d(0, ${props => props.open ? '0' : '-100%'}, 0);
        transition: all .25s ease-out;
        top: 0px;
        width: 100vw;
        z-index: 999;
    }
`;

const StyledListItem = styled.li`
    list-style: outside none none;
    margin: 0;
    padding: 8px;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;

    @media only screen and (max-width: 1000px) {
        margin: 4px 0;
    }
`;

const StyledLink = styled(Link)`
    font-weight: 900;
    color: #fff;
    text-decoration: none;

    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #fff;
    }
`


const NavigationList = ({ open, clickHandler, screenSize }) => {
    const childItems = (screenSize) => {
        return (
            <>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/">Home</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/press">Press</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/about-us">About us</StyledLink>
                </StyledListItem>
            </>
        )
    }

    return (
        <StyledList open={open}>
            {childItems(screenSize)}
        </StyledList>
    )
};

export default NavigationList
