import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IconLoop = ({color}) => {
  const data = useStaticQuery(graphql`
    query {
      color: file(relativePath: { eq: "icons/loop.png" }) {
        childImageSharp {
          fluid(maxWidth: 248) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      white: file(relativePath: { eq: "icons/loop-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 248) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  return color === 'white' ? <Img fluid={data.white.childImageSharp.fluid} alt="A GAME ABOVE LIFECYCLE LOOP" /> : <Img fluid={data.color.childImageSharp.fluid} alt="A GAME ABOVE LIFECYCLE LOOP" />
}

export default IconLoop
