import React from "react";

import './burgericon.css'

const BurgerIcon = ({ open, color, clickHandler, ...props }) => (
  <a className={open ? "burger-menu open" : "burger-menu"} role='button' tabIndex={0} onClick={clickHandler} onKeyDown={clickHandler} {...props}>
    <div style={{backgroundColor: open ? '#fff' : color}} className="bar1" key="b1" />
    <div style={{backgroundColor: open ? '#fff' : color}} className="bar2" key="b2" />
    <div style={{backgroundColor: open ? '#fff' : color}} className="bar3" key="b3" />
  </a>
);

export default BurgerIcon
