import React, { useState } from "react";

import Logotype from '../atoms/Logotype'
import BurgerIcon from "../atoms/BurgerIcon";
import NavigationList from '../atoms/NavgationList'

import './navigation.css'

const Navigation = ({sticky, variant}) => {
    const logotypeSize = sticky ? 140 : 240
    const breakpoint = 1000;
    const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1000;
    const [width, setWidth] = useState(windowWidth);
    const [open, setOpen] = useState(false);

    const clickHandler = () => {
        if (width < breakpoint) {
            setOpen(!open);
        }
    }

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
          const handleWindowResize = () => setWidth(window.innerWidth)
          window.addEventListener("resize", handleWindowResize);
  
          return () => window.removeEventListener("resize", handleWindowResize);
        }
    }, []);

    return (
        <nav className={sticky ? "navbar navbar-sticky" : 'navbar'}>
            <Logotype width={logotypeSize} height={logotypeSize / 2} mobileWidth={logotypeSize / 1.4} mobileHeight={logotypeSize / 1.4 / 2} color='blue' link={true} />
            {width < breakpoint ? <BurgerIcon color={'#fff'} open={open} clickHandler={clickHandler} /> : ''}
            <NavigationList open={open} clickHandler={clickHandler} screenSize={width < breakpoint ? 'small' : 'large'} />
        </nav>
    )
}

export default Navigation