import React from "react"
import styled from "styled-components"
import * as Icon from 'react-feather'

import Logotype from '../atoms/Logotype'
import Grid from '../atoms/Grid'
import GridItem from '../atoms/GridItem'
import Container from '../molecules/Container'

const StyledFooter = styled.footer`
    background: #865d48;
    padding: 1rem;
    width: 100%;
`;

const StyledParagraph = styled.p`
    color: #fff;
    border-left: 1px solid white;
    display: inline;
    float: left;
    font-size: 12px;
    line-height: 1.5;
    margin: 0 0 0 16px;
    padding-left: 16px;

    @media only screen and (max-width: 1000px) {
        clear: left;
        border: none;
        margin: 0;
        padding: 0;
    }
`;
const StyledAddress = styled.address`
    color: #fff;
    border-left: 1px solid white;
    display: inline;
    float: left;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 0 16px;
    padding-left: 16px;
    width: 80%;

    a {
        color: #fff;
        font-size: 12px;
    }

    @media only screen and (max-width: 1000px) {
        clear: left;
        border: none;
        margin: 0;
        padding: 0;
    }
`;

const Footer = () => {
    return (
        <StyledFooter className="clearfix">
            <Container>
                <Grid>
                    <GridItem size='75%'>
                        <Logotype width='80' height='80' color='white' style={{float: 'left', marginBottom: '16px'}} />
                        <StyledParagraph>
                            © {new Date().getFullYear()} - A GAME ABOVE LLC <br />
                        </StyledParagraph>
                        <StyledAddress>
                            Mail: <a href="mailto:info@agameabove.com">info@agameabove.com</a> <br />
                            Address: A GAME ABOVE LLC - 4610 S. Ulster Street Suite 150 - Denver - Colorado - CO 80237 - USA
                        </StyledAddress>
                    </GridItem>
                    <GridItem size='25%' style={{marginTop: '16px'}}>
                        <a href="https://www.facebook.com/A-Game-Above-102736011465854" target="_blank" rel="noopener noreferrer" style={{padding: '.2rem', lineHeight: '1', display: 'inline-block'}}><Icon.Facebook size={24} color={'#fff'} /></a>
                        <a href="https://twitter.com/agameabove" target="_blank" rel="noopener noreferrer" style={{padding: '.2rem', lineHeight: '1', display: 'inline-block'}}><Icon.Twitter size={24} color={'#fff'} /></a>
                        <a href="https://www.linkedin.com/company/a-game-above/" target="_blank" rel="noopener noreferrer" style={{padding: '.2rem', lineHeight: '1', display: 'inline-block'}}><Icon.Linkedin size={24} color={'#fff'} /></a>
                        <a href="https://www.instagram.com/agameabove/" target="_blank" rel="noopener noreferrer" style={{padding: '.2rem', lineHeight: '1', display: 'inline-block'}}><Icon.Instagram size={24} color={'#fff'} /></a>
                        <a href="https://vimeo.com/agameabove" target="_blank" rel="noopener noreferrer" style={{padding: '.2rem', lineHeight: '1', display: 'inline-block'}}><Icon.Film size={24} color={'#fff'} /></a>
                    </GridItem>
                </Grid>
            </Container>
        </StyledFooter>
    )
}

export default Footer