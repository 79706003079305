import React from "react";
import styled from 'styled-components';

import Agameabove from './agameabove_2021.mp4'

const StyledContainer = styled.div`
    min-height: 100vh;
    z-index: 100;
    position: relative;
`

const StyledVideo = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%; 
    overflow: hidden;
    z-index: -1;
`

const BackgroundVideo = ({children}) => (
    <StyledContainer>
        <StyledVideo>
            <video playsInline autoPlay muted loop style={{
                position: 'absolute',
                width: '100%',
                height: '100%', 
                left: '0',
                top: '0',
                objectFit: 'cover'
            }}>
                <source src={Agameabove} type='video/mp4' />
            </video>
        </StyledVideo>
        {children}
    </StyledContainer> 
);

export default BackgroundVideo
