import React from 'react';
import styled from 'styled-components';

import IconArrow from './IconArrow'
import IconBox from './IconBox'
import IconLockMoney from "./IconLockMoney"
import IconMaze from "./IconMaze"
import IconPeople from "./IconPeople"
import IconPress from "./IconPress"
import IconPlacemaker from './IconPlaceMaker'
import IconSniperScope from './IconSniperScope'
import IconShakeHands from "./IconShakeHands"
import IconTargetSuccess from './IconTargetSuccess';
import IconStory from './IconStory';
import IconLoop from './IconLoop';

const StyledColor = styled.div`
  width: ${props => props.size ? props.size : '248px'};
  max-width: ${props => props.size ? props.size : '248px'};
  margin: 16px auto;
  z-index: -1;
`;
const StyledWhite = styled.div`
  background: ${props => props.background};
  padding: ${props => props.padding ? props.padding + 'px' : '48px'};
  border-radius: 100%;
  margin: 16px auto;
  width: 248px;
  height: 248px;

  @media only screen and (max-width: 600px) {
    padding: ${props => props.padding ? props.padding / 2 + 'px' : '24px'};
    width: 124px;
    margin: 0 auto 16px;
    height: 124px;
  }
  
  @media only screen and (max-width: 1000px) {
    padding: ${props => props.padding ? props.padding / 3 * 2 + 'px' : '32px'};
    width: 165px;
    margin: 0 auto 16px;
    height: 165px;
  }
`;

const Icon = ({ icon, color, size, background }) => {
    let IconComponent = null;
    let padding = null;

    switch (icon) {
        case 'arrow':
            IconComponent = () => <IconArrow color={color} />;
            break;
        case 'box':
            IconComponent = () => <IconBox color={color} />;
            padding = 32
            break;
        case 'maze':
            IconComponent = () => <IconMaze color={color} />;
            padding = 24
            break;
        case 'lockmoney':
            IconComponent = () => <IconLockMoney color={color} />;
            break;
        case 'people':
            IconComponent = () => <IconPeople color={color} />;
            break;
        case 'press':
            IconComponent = () => <IconPress color={color} />;
            padding = 32
            break;
        case 'placemaker':
            IconComponent = () => <IconPlacemaker color={color} />;
            break;
        case 'sniperscope':
            IconComponent = () => <IconSniperScope color={color} />;
            break;
        case 'shakehands':
            IconComponent = () => <IconShakeHands color={color} />;
            padding = 32
            break;
        case 'targetsuccess':
            IconComponent = () => <IconTargetSuccess color={color} />;
            padding = 32
            break;
        case 'story':
            IconComponent = () => <IconStory color={color} />;
            padding = 32
            break;
        case 'loop':
            IconComponent = () => <IconLoop color={color} />;
            padding = 32
            break;
        default:
            IconComponent = () => 'NOT FOUND';
            break;
    }

    return color === 'white' ? 
        <StyledWhite background={background} padding={padding} size={size}>{IconComponent()}</StyledWhite> : 
        <StyledColor padding={padding} size={size}>{IconComponent()}</StyledColor>
}
 
export default Icon