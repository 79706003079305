import React from "react"
import styled from 'styled-components';

import Navigation from '../molecules/Navigation'
import BackgroundImage from '../atoms/BackgroundImage'
import BackgroundVideo from '../atoms/BackgroundVideo'

const StyledHeader = styled.header`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
`;

const Header = ({ headerColor, variant, children, sticky }) => {
    const navigation =
        <>
            <StyledHeader>
                <Navigation sticky={sticky} variant={variant} />
                {children}
            </StyledHeader>
        </>

    return (
        <>
            {
                variant === 'movie' ? 
                <BackgroundVideo>
                    {navigation}
                </BackgroundVideo> :
                <BackgroundImage color={headerColor}>
                    {navigation}
                </BackgroundImage>
            }
        </>
    )
}

export default Header
