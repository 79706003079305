import { useEffect, useState, useRef, useCallback } from "react"

function useSticky() {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  const handleScroll = useCallback(() => {
    if (typeof window !== 'undefined' && element.current !== null) {
        window.scrollY > element.current.getBoundingClientRect().bottom
        ? setSticky(true)
        : setSticky(false)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
        window.addEventListener("scroll", handleScroll)
        
        return () => {
          window.removeEventListener("scroll", () => handleScroll)
        }
    }
  }, [handleScroll])

  return { isSticky, element }
}

export default useSticky