import React from "react";
import styled from "styled-components"

const TransparentBox = styled.div`
    color: #fff;
    padding: 4rem 8rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const WhiteBox = styled.div`
    border-radius: 8px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9, 
                -20px -20px 60px #ffffff;
    padding: 2rem;
    text-align: left;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
        margin: 48px 0;
        text-align: center;
    }
`;

const GoldBox = styled.div`
    background: #A2845C;
    color: #fff;
    padding: 4rem 8rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const BlueBox = styled.div`
    background: #24859B;
    color: #fff;
    padding: 4rem 8rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const GreyBox = styled.div`
    background: #E7E6E3;
    color: #000;
    padding: 4rem 8rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const BrownBox = styled.div`
    background: #865d48;
    color: #fff;
    padding: 4rem 8rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`; 

const Box = ({children, color, fullWidth, ...props}) => {
    const extendedClass = fullWidth ? 'FullWidth' : ''
    let BoxComponent = null;

    switch (color) {
        case 'gold':
            BoxComponent = ( children ) => <GoldBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></GoldBox>;
            break;
        case 'blue':
            BoxComponent = ( children ) => <BlueBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></BlueBox>;
            break;
        case 'grey':
            BoxComponent = ( children ) => <GreyBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></GreyBox>;
            break;
        case 'brown':
            BoxComponent = ( children ) => <BrownBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></BrownBox>;
            break;
        case 'white':
            BoxComponent = ( children ) => <WhiteBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></WhiteBox>;
            break;
        default:
            BoxComponent = ( children ) => <TransparentBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></TransparentBox>;
            break;
    }

    return BoxComponent(children)
}

export default Box
